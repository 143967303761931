import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import logo from "../../src/assets/logo-website.png";

const Contentitem = ({ data }) => {
const item = data.kontentItemContentitem.elements
const {edges: posts} = data.allKontentItemPost
const siteURLPath = data.site.siteMetadata.siteUrl
let urlSlash = "/";
if (item.basic__heading.value === "Ilesh Mistry's website") {
   urlSlash = "";
}

const urlPath = siteURLPath + urlSlash + data.kontentItemContentitem.fields.slug + urlSlash;

    return (
    <Layout>
        <Helmet>
          <html lang="en" />

          {/* General tags */}
          <title>{item.metadata__title.value} | Ilesh Mistry's website</title>
          <meta name="description" content={item.metadata__description.value} />
          <meta name="image" content={siteURLPath + logo} />
          <link rel="canonical" href={urlPath} />

          {/* Twitter Card tags */}
          <meta name="twitter:image" content={siteURLPath + logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@ileshmistry" />
          <meta name="twitter:title" content={item.metadata__title.value} />
          <meta name="twitter:description" content={item.metadata__description.value} />
          <meta name="twitter:image" content={siteURLPath + logo} />
          <meta name="twitter:site" content={siteURLPath + urlSlash} />
          <meta name="twitter:url" content={urlPath} />

          {/* OpenGraph tags */}
          <meta property="og:url" content={urlPath} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={item.metadata__title.value} />
          <meta property="og:description" content={item.metadata__description.value} />
          <meta property="og:image" content={siteURLPath + logo} />
          <meta property="og:image:secure_url" content={siteURLPath + logo} />
        </Helmet>

        <h1>{item.basic__heading.value}</h1>
        <div dangerouslySetInnerHTML={{ __html: item.basic__content.value }} />
        
       

<div className="post-wrapper">
      {posts.map (({node: post}) => {
        if (item.basic__heading.value !== "Posts") {
          return ""
        }
        else return (
          <article className="post-articles">
            <Link to={post.fields.slug + urlSlash} className="p-listing-link">
              <div>
                <h3 class="p-a-header">
                  {post.elements.basic__heading.value}
                </h3>
                <div class="gatsby-image-wrapper">
                  <img src={post.elements.metadata__image.value[0].url+"?w=320&h=200&fit=crop"} alt={post.elements.metadata__image.value[0].description}/>
                </div>
                <small>{post.elements.date.value}</small>
                <p>{post.elements.metadata__description.value}</p>
              </div>
            </Link>
          </article>
        );
      })}
      </div>
    </Layout>
    )
}

export default Contentitem

export const query = graphql`
  query contentitemQuery($slug: String!) {
    kontentItemContentitem(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      elements {
        basic__content {
          value
        }
        basic__heading {
          value
        }
        metadata__title {
            value
        }
        metadata__description {
            value
        }
        metadata__keywords {
            value 
        }
        metadata__image {
            value {
                url
            }
        }
      }
    }

    allKontentItemPost(sort: {order: DESC, fields: [elements___date___value]}) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          elements {
            basic__heading {
              value
            }
            date {
              value(formatString: "DD MMMM YYYY")
            }
            metadata__description {
              value
            }
            metadata__image {
              value {
                url
                description
              }
            }
            
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
        locale
        lang
        title
        twitter
        description
        image
        thumbnail
      }
    }
  }
`